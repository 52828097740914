import React from 'react';

import { MapContainer, TileLayer, Popup, Circle } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { MdBarChart } from "react-icons/md";

const Map = ({cities, ...rest}) => {
//   const cities = [
//     { name: 'Roma', position: [41.9028, 12.4964] },
//     { name: 'Milano', position: [45.4642, 9.1900] },
//     // Aggiungi altre città qui
//   ];

if(cities.length === 0) return;

// console.log(cities);

  return (
    <div>
      <div className="mb-auto flex items-center justify-between px-6">
        <h2 className="text-lg font-bold text-navy-700 dark:text-white">
          Panoramica delle visualizzazioni
        </h2>
        <button className="!linear z-[1] flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 !transition !duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
          <MdBarChart className="h-6 w-6" />
        </button>
      </div>

      <div className="mt-5">
        <div className=" w-full">
            <MapContainer
                center={[41.8719, 12.5674]} 
                zoom={5} 
                style={{ height: "400px", width: "100%", borderRadius: '.6rem' }}
                zoomControl={false}   //Disattiva lo zoome
                dragging={false} // Disattiva il trascinamento
                scrollWheelZoom={false} // Disattiva lo zoom con la rotella del mouse
                doubleClickZoom={false} // Disattiva lo zoom con il doppio clic
                >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {cities.map(city => (
                    <Circle
                        center={city.position}
                        pathOptions={{ fillColor: 'blue' }}
                        radius={200}
                    >
                        <Popup>{city.name}: {city.views}</Popup>
                    </Circle>
                ))}
                </MapContainer>
        </div>
    </div>
    </div>
    
  );
};

export default Map;
