import React, {forwardRef } from 'react';

const Card = forwardRef(({ variant, extra, children, ...rest }, ref) => {

  return (
    <div
      className={`!z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none ${extra}`}
      {...rest} ref={ref} 
    >
      {children}
    </div>
  );
});

export default Card;
