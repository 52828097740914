import React, { useState,useEffect } from "react";
import Card from "components/card";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Eye, Pencil, X, Check } from "@phosphor-icons/react";
import Editor from 'react-simple-wysiwyg';

import { Button, Form, Input, Radio, message, InputNumber, Switch, Upload ,Modal, Checkbox} from "antd";
import axios from "axios";
import {ClientInfoUpdate} from 'views/admin/GraphQl/ClientInfo';

const { TextArea } = Input;

const Banner = (props) => {
    const [updateClientInfo] = ClientInfoUpdate();

    
    const [form] = Form.useForm();
    
    const [clientInfo, setClientInfo] = useState({});
    const [load, setLoad] = useState(false);
    const [delivery, setDelivery] = useState(true);
    const [wifi, setWifi] = useState(true);
    const [gluten_, setGluten_] = useState(true);
    const [lactose_, setLactose_] = useState(true);
    const [loadingLogo, setLoadingLogo] = useState(false);
    const [logoUrl, setLogo] = useState()

    const [loadingCover, setLoadingCover] = useState(false);
    const [coverUrl, setCover] = useState()

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');

    const handleSubmit = (e) => {

        /*const clientInfos = Object.keys(clientInfo).map((e) => { console.log(e);
            return  (clientInfo[e] === "");
        });

        
        if(clientInfos.includes(true)){
            message.error("Compila tutti i campi");
            return;
        }*/
        
        if(clientInfo.activity_name === "" || 
            clientInfo.activity_type === "" || 
            clientInfo.Logo === "" || 
            clientInfo.header_cover === "" || 
            clientInfo.descriptions === "" || 
            clientInfo.Street === "" || 
            clientInfo.City === "" || 
            clientInfo.parcheggio === ""){
            message.error("Compila tutti i campi");
            return;
        }

        
        if(clientInfo.client_type_filter.length == 0){
            message.error("Devi inserire almeno 1 attività secondaria ");
            return;
        }


        const key = "init";
        setLoad(false);

        const updatedClientInfo = JSON.parse(JSON.stringify(clientInfo));

        delete updatedClientInfo.__typename;
        delete updatedClientInfo.gallerys;
        delete updatedClientInfo.clienti_calends;
        delete updatedClientInfo._id;
        delete updatedClientInfo.owner;
        delete updatedClientInfo.active;
        delete updatedClientInfo.updateAt;
        delete updatedClientInfo.createAt;
        delete updatedClientInfo.sponsor;
        

        updateClientInfo({
            variables: {
                storeClientInfoInput: updatedClientInfo
            }
        }).then(({data}) => {
            //Login avvenuto con successo
            message.open({
                key,
                type: "success",
                content: "Informazioni aggiornate!",
            });
            setLoad(true);
        })
        .catch((error) => {
            console.error('Si è verificato un errore:', error);
            message.open({
                key,
                type: "error",
                content: error.message,
            });
        });
        // axios.post(process.env.REACT_APP_END_POINT_API,
        //     {
        //         query: {},
        //         mutation: {
        //             clienti_infosByIdUpdate: {
        //                 id: JSON.parse(localStorage.getItem("tk"))._id,
        //                 keys: clientInfo,
        //             },
        //         },
        //     },
        //         {
        //         headers: {
        //             "Content-Type": "application/json",
        //             "auth-api-token":"ef29b61efc28755cbf9a513eb857bd181d60a423ba6c2f6f0c9b90308a41d771",
        //             "cache-ctr": "64c694b0ab6e70e4eeb0cb47",
        //         },
        //     }
        // )
        // .then((response) => {
        //     message.open({
        //         key,
        //         type: "success",
        //         content: "Informazioni aggiornate!",
        //     });
        //     setLoad(true);
        // })
        // .catch((err) => {
        //     if (err.response.status === 400) {
        //         message.open({
        //             key,
        //             type: "error",
        //             content: err.response,
        //         });
        //     }
        // });
    };
    
    useEffect(() => {
        setClientInfo(props.data);
        setLogo(props.data.Logo);
        setCover(props.data.header_cover);
    }, [props.data])

    
    const onInputChange  = (e)  =>{
        const name = e.target.name;
        const value = e.target.value;
        setClientInfo({...clientInfo, [name]: value});
    }
    
    const onDeliveryCost = (e) =>{
        setClientInfo({...clientInfo, delivery_cost: e});
    }

    const onCopertoCost = (e) =>{
        if(e == null || e == ""){
            e = 0;
        }
        setClientInfo({...clientInfo, coperto: e});
    }

    const onLactoseCost = (e) =>{
        if(e == null || e == ""){
            e = 0;
        }
        setClientInfo({...clientInfo, lactosefree_cost: e});
    }

    const onGlutenCost =  (e) =>{
        setClientInfo({...clientInfo, glutenfree_cost: e});
    }

    const deliverySwitch= (e) =>{
        setDelivery(e);
        if(e === false){
            setClientInfo({...clientInfo, delivery_cost: 0});
        }
        setClientInfo({...clientInfo, delivery: e});
    }

    const wifiSwitch = (e) =>{
        setWifi(e);
        if(e === false){
            setClientInfo({...clientInfo, wifi_space: ''});
        }
    }

    const onVeganCost =  (e) =>{
        if(e == null || e == ""){
            e = 0;
        }
        setClientInfo({...clientInfo, vegan_cost: e});
    }

    const onVegetarianCost =  (e) =>{
        if(e == null || e == ""){
            e = 0;
        }
        setClientInfo({...clientInfo, vegetarian_cost: e});
    }

    const gluten = (e) =>{
        setLactose_(e);
        if(e === false){
            
            setClientInfo({...clientInfo, glutenfree_cost: 0});
        }
        setClientInfo({...clientInfo, glutenfree: e});
    }

    const vegan = (e) =>{
        // setLactose_(e);
        if(e === false){
            
            setClientInfo({...clientInfo, vegan_cost: 0});
        }
        setClientInfo({...clientInfo, veganfree: e});
    }

    const vegetarian = (e) =>{
        // setLactose_(e);
        if(e === false){
            
            setClientInfo({...clientInfo, vegetarian_cost: 0});
        }
        setClientInfo({...clientInfo, vegetarianfree: e});
    }

    const lactose= (e) =>{
        if(e === false){
            setClientInfo({...clientInfo, lactosefree_cost: 0});
        }
        setClientInfo({...clientInfo, lactosefree: e});
    }

    const aprking = (e) =>{
        setClientInfo({...clientInfo, parcheggio: e});
    }

    const pet = (e) => {
        setClientInfo({...clientInfo, pet: e});
    }


    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };
    
    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoadingLogo(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            
            const key = "init";
            axios.post(process.env.REACT_APP_END_POINT_API,
                {
                    query: {},
                    mutation: {
                        clienti_infosByIdUpdate: {
                            id: JSON.parse(localStorage.getItem("tk")).user._id,
                            keys: {
                                "Logo": info.file.response.imageUrl
                            },
                        },
                    },
                },
                    {
                    headers: {
                        "Content-Type": "application/json",
                        "auth-api-token":"ef29b61efc28755cbf9a513eb857bd181d60a423ba6c2f6f0c9b90308a41d771",
                        "cache-ctr": "64c694b0ab6e70e4eeb0cb47",
                    },
                }
            )
            .then((response) => {
                message.open({
                    key,
                    type: "success",
                    content: "Logo attività aggiornato",
                });
            })
            .catch((err) => {
                if (err.response.status === 400) {
                    message.open({
                        key,
                        type: "error",
                        content: err.response,
                    });
                }
            });

            setClientInfo({...clientInfo, Logo: info.file.response.imageUrl});

            getBase64(info.file.originFileObj, (url) => {
                setLoadingLogo(false);
                setLogo(url);
            });
        }
    };

    const HeaderCoverChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoadingCover(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            
            const key = "init";
            axios.post(process.env.REACT_APP_END_POINT_API,
                {
                    query: {},
                    mutation: {
                        clienti_infosByIdUpdate: {
                            id: JSON.parse(localStorage.getItem("tk")).user._id,
                            keys: {
                                "header_cover": info.file.response.imageUrl
                            },
                        },
                    },
                },
                    {
                    headers: {
                        "Content-Type": "application/json",
                        "auth-api-token":"ef29b61efc28755cbf9a513eb857bd181d60a423ba6c2f6f0c9b90308a41d771",
                        "cache-ctr": "64c694b0ab6e70e4eeb0cb47",
                    },
                }
            )
            .then((response) => {
                message.open({
                    key,
                    type: "success",
                    content: "Cover pagina menù aggiornato",
                });
            })
            .catch((err) => {
                if (err.response.status === 400) {
                    message.open({
                        key,
                        type: "error",
                        content: err.response,
                    });
                }
            });

            setClientInfo({...clientInfo, header_cover: info.file.response.imageUrl});

            getBase64(info.file.originFileObj, (url) => {
                setLoadingCover(false);
                setCover(url);
            });
        }
    };
    
    const uploadButton = (
        <div>
            {loadingLogo || loadingCover ? <LoadingOutlined /> : <PlusOutlined />}
            
            <div
                style={{
                marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleCancel = () => setPreviewOpen(false);
    
    const onTypefilter = (e) => {
        setClientInfo({...clientInfo, client_type_filter: e});
    }

    const removeLogo = () => {
        setLogo("");
    }

    const removeCover= () => {
        setCover("");
    }

    const parckGame = (e) => {
        setClientInfo({...clientInfo, area_giochi_active: e});
    }

    const coperto = (e) => {
        setClientInfo({...clientInfo, coperto_active: e});
    }

    const smokingArea = (e) => {
        setClientInfo({...clientInfo, area_fumatori_active: e});
    }
    const piscina = (e) => {
        setClientInfo({...clientInfo, piscina_active: e});
    }


    if(clientInfo['client_type_filter'] === undefined) return;
    
    // clientInfo['client_type_filter'] = [].concat(...clientInfo['client_type_filter']);
    //setClientInfo(clientInfo);
    
    return (
        <>
            <Card extra={"items-left w-full p-[16px] bg-cover"}>
                <Form form={form} onFinish={handleSubmit} layout="vertical">
                    <Form.Item
                        label="Logo attività"
                        required
                        tooltip="Questo campo è obbligatorio"
                    >
                        <div className="relative inline-flex flex-center">
                            <Upload
                                name="image"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action="https://server.mynuapp.it/upload/image"
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                            >
                                {logoUrl ? (
                                <img
                                    src={logoUrl}
                                    alt="avatar"
                                    style={{
                                    width: '100%',
                                    }}
                                />
                                ) : (
                                    uploadButton
                                )}
                            </Upload>
                            <Button type="text" className="" onClick={removeLogo} disabled={!logoUrl} icon={<X className="top-[2px] relative" />}>Rimuovi immagine</Button>
                        </div>
                        {/*<Input onChange={onInputChange} name="Logo" value={clientInfo['Logo']} placeholder={clientInfo['Logo']} />*/}
                    </Form.Item>
                    <Form.Item
                        label="Cover pagina menù"
                        required
                        tooltip="Immagine parte superiore WebMenù"
                    >
                        <div className="relative inline-flex flex-center">
                            <Upload
                                name="image"
                                listType="picture-card"
                                className="avatar-uploader !w-auto"
                                showUploadList={false}
                                action="https://server.mynuapp.it/upload/image"
                                beforeUpload={beforeUpload}
                                onPreview={handlePreview}
                                onChange={HeaderCoverChange}
                            >
                                {coverUrl ? (
                                
                                    <img
                                        src={coverUrl}
                                        alt="avatar"
                                        style={{
                                            width: '100%',
                                        }}
                                    />

                                ) : (
                                    uploadButton
                                )}
                            </Upload>
                            <div className="grid content-center">
                                <Button type="text" className="" onClick={removeCover} disabled={!coverUrl} icon={<X className="top-[2px] relative" />}>Rimuovi immagine</Button>
                                <Button type="text" className="" onClick={(e) => { e.preventDefault(); handlePreview({
                                                                                            uid: '-1',
                                                                                            name: 'Cover Menù',
                                                                                            status: 'done',
                                                                                            url: coverUrl,
                                                                                            }) }} disabled={!coverUrl} icon={<Eye className="top-[2px] relative" />}>Mostra Copertina</Button>
                            </div>
                        </div>
                        <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                            <img
                                alt="example"
                                style={{
                                    width: '100%',
                                }}
                                src={previewImage}
                            />
                        </Modal>
                        {/*<Input onChange={onInputChange} name="header_cover" value={clientInfo['header_cover']} placeholder={clientInfo['header_cover']} />*/}
                    </Form.Item>
                    
                    <Form.Item
                        label="Nome e Cognome del proprietario"
                        required
                        tooltip="Questo campo è obbligatorio"
                    >
                        <Input onChange={onInputChange} name="owner_name" value={clientInfo['owner_name']} placeholder={clientInfo['owner_name']} />
                    </Form.Item>
                    <Form.Item
                        label="Nome Attività"
                        required
                        tooltip="Inserisci il nome della tua attività"
                        
                    >
                        <Input
                            name="activity_name"
                            placeholder={clientInfo['activity_name']}
                            value={clientInfo['activity_name']}
                            defaultValue={clientInfo['activity_name']}
                            onChange={onInputChange}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Tipo di attività"
                        required
                        tooltip="Inserisci la categoria della tua attività"
                    >
                        <Radio.Group
                            onChange={onInputChange}
                            value={clientInfo['activity_type']}
                            required
                            defaultChecked={clientInfo['activity_type']}
                            name="activity_type"
                        >
                            <Radio value={"store"} disabled={clientInfo['activity_type'] != "store"}>Store</Radio>
                            <Radio value={"risto"} disabled={clientInfo['activity_type'] != "risto"}>Ristoro</Radio>
                            <Radio value={"evento"} disabled={clientInfo['activity_type'] != "evento"}>Evento</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label="Attività secondaria"
                        required
                        tooltip="Inserisci altri servizi che offre la tua attività"
                    >
                        <Checkbox.Group options={[
    {
        label: 'Pizzeria',
        value: 'pizzeria',
    },
    {
        label: 'Ristorante',
        value: 'ristorante',
    },
    {
        label: 'Pub',
        value: 'pub',
    },
    {
        label: 'Bar',
        value: 'bar',
    },
    {
        label: 'Sala Ricevimenti',
        value: 'sala ricevimenti',
    },
    {
        label: 'Trattoria',
        value: 'trattoria',
    },
    {
        label: 'Osteria',
        value: 'osteria',
    },
    {
        label: 'Tavola calda',
        value: 'tavola calda',
    },
    {
        label: 'Rosticceria',
        value: 'rosticceria',
    },
    {
        label: 'Caffetteria',
        value: 'caffetteria',
    },
    {
        label: 'Lounge',
        value: 'lounge',
    },
    {
        label: 'Birreria',
        value: 'birreria',
    },
    {
        label: 'Wine bar',
        value: 'wine bar',
    },
    {
        label: 'Steakhouse',
        value: 'steakhouse',
    },
    {
        label: 'Sushi bar',
        value: 'sushi bar',
    },
    {
        label: 'Tavola fredda',
        value: 'tavola fredda',
    },
    {
        label: 'Gelateria',
        value: 'gelateria',
    },
    {
        label: 'Cucina etnica',
        value: 'cucina etnica',
    },
    {
        label: 'Fast food',
        value: 'fast food',
    },
    {
        label: 'Food truck',
        value: 'food truck',
    }
    ]} defaultValue={clientInfo['client_type_filter']} name="client_type_filter" onChange={onTypefilter} />
                    </Form.Item>

                    <Form.Item
                        label="Tel. / Cell."
                        tooltip="Inserisci un recapito telefonico"
                    >
                        <Input
                            name="phone"
                            placeholder={clientInfo['phone']}
                            value={clientInfo['phone']}
                            defaultValue={clientInfo['phone']}
                            onChange={onInputChange}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Titolo descrizione footer"
                    >
                        <Input onChange={onInputChange} name="footer_present" value={clientInfo['footer_present']} placeholder={!clientInfo['footer_present'] ? "Il nostro locale" : clientInfo['footer_present']} />
                    </Form.Item>
                    <Form.Item
                        label="Descrizione attività footer"
                        required
                        tooltip="Inserisci la descrizione della tua attività"
                        
                    >
                        <TextArea rows={4} placeholder={clientInfo['descriptions']} name="descriptions"
                            value={clientInfo['descriptions']}
                            defaultValue={clientInfo['descriptions']}
                            onChange={onInputChange} maxLength={250} />
                    </Form.Item>
                    
                    <Form.Item
                        label="Titolo descrizione estesa"
                    >
                        <Input onChange={onInputChange} name="title_descriptions_full" value={clientInfo['title_descriptions_full']} placeholder={!clientInfo['title_descriptions_full'] ? "Chi siamo" : clientInfo['title_descriptions_full']} />
                    </Form.Item>
                    <Form.Item
                        label="Descrizione estesa"
                        tooltip="Aggiungi una descrizione estesa della tua attività nel corpo della pagina"
                    >
                        <Editor className="min-h-[500px]" value={clientInfo['descriptions_full']} name="descriptions_full" onChange={onInputChange} />
                    </Form.Item>

                    <Form.Item
                        label="Via della tua attività"
                        required
                        tooltip="Inserisci la via della tua attività"
                        
                    >
                        <Input
                            placeholder={clientInfo['Street']}
                            value={clientInfo['Street']}
                            name="Street"
                            defaultValue={clientInfo['Street']}
                            onChange={onInputChange}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Città della tua attività"
                        required
                        tooltip="Inserisci la città della tua attività"
                        
                    >
                        <Input
                            name="City"
                            placeholder={clientInfo['City']}
                            value={clientInfo['City']}
                            defaultValue={clientInfo['City']}
                            onChange={onInputChange}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Cap della tua attività"
                        required
                        tooltip="Inserisci il cap della tua attività"
                        
                    >
                        <Input
                            name="postal_code"
                            placeholder={clientInfo['postal_code']}
                            value={clientInfo['postal_code']}
                            defaultValue={clientInfo['postal_code']}
                            onChange={onInputChange}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Facebook Url"
                        required
                        tooltip="Inserisci l'Url della tua pagina Facebook"
                        
                    >
                        <Input
                            name="facebook_link"
                            placeholder={clientInfo['facebook_link']}
                            value={clientInfo['facebook_link']}
                            defaultValue={clientInfo['facebook_link']}
                            onChange={onInputChange}
                        />
                    </Form.Item>

                    <Form.Item
                        label="TikTok Url"
                        required
                        tooltip="Inserisci l'Url della tua pagina TikTok"
                        
                    >
                        <Input
                            name="tiktok_link"
                            placeholder={clientInfo['tiktok_link']}
                            value={clientInfo['tiktok_link']}
                            defaultValue={clientInfo['tiktok_link']}
                            onChange={onInputChange}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Instagram Url"
                        required
                        tooltip="Inserisci l'Url della tua pagina Instagram"
                        
                    >
                        <Input
                            name="instagram_link"
                            placeholder={clientInfo['instagram_link']}
                            value={clientInfo['instagram_link']}
                            defaultValue={clientInfo['instagram_link']}
                            onChange={onInputChange}
                        />
                    </Form.Item>

                    <Form.Item
                        label="YouTube Url"
                        required
                        tooltip="Inserisci l'Url della tua pagina Youtube"
                    >
                        <Input
                            name="youtube_link"
                            placeholder={clientInfo['youtube_link']}
                            value={clientInfo['youtube_link']}
                            defaultValue={clientInfo['youtube_link']}
                            onChange={onInputChange}
                        />
                    </Form.Item>
                    


                    <Form.Item>
                        <Button htmlType="submit"
                            className="bg-brand-500 hover:!bg-brand-600"
                            type="primary"
                        >
                            Aggiorna
                        </Button>
                    </Form.Item>
                    
                </Form>
            </Card>
            
            <div>
                <h2 className="text-2xl mb-3 mt-5">• Servizi</h2>
                <Card extra={"items-left w-full p-[16px] bg-cover"}>
                    <Form form={form} onFinish={handleSubmit} layout="vertical">
                        <Form.Item
                            label="Delivery"
                            tooltip="La tua attività svolge anche delivery?"
                        >
                            <Switch 
                            className="bg-gray-300"
                            checkedChildren={<Check className="mt-[2px]" size={16} />}
                            unCheckedChildren={<X className="mt-[1px]" size={16} />}
                            onChange={deliverySwitch} checked={clientInfo.delivery}  />
                        </Form.Item>
                        <Form.Item
                            label="Costo delivery"
                            tooltip="Inserisci il costo del delivery se disponibile"
                        >
                            <InputNumber min={0} max={99}  className="w-full"
                                name="delivery_cost"
                                formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                disabled={!clientInfo['delivery']}
                                value={clientInfo['delivery_cost']}
                                defaultValue={clientInfo['delivery_cost']}
                                onChange={onDeliveryCost}
                            />
                        </Form.Item>


                        <Form.Item
                            label="Alimenti senza lattosio"
                            tooltip="La tua attività ha alimenti senza lattosio?"
                        >
                            <Switch 
                            className="bg-gray-300"
                            checkedChildren={<Check className="mt-[2px]" size={16} />}
                            unCheckedChildren={<X className="mt-[1px]" size={16} />}
                            onChange={lactose} checked={clientInfo.lactosefree}  />
                        </Form.Item>
                        <Form.Item
                            label="Costo servizio senza lattosio?"
                            tooltip="Inserisci il costo del servizio se disponibile"
                        >
                            <InputNumber min={0} max={99} className="w-full"
                                name="lactosefree_cost"
                                formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                disabled={!clientInfo['lactosefree']}
                                value={clientInfo['lactosefree_cost']}
                                defaultValue={clientInfo['lactosefree_cost']}
                                onChange={onLactoseCost}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Alimenti senza glutine"
                            tooltip="La tua attività ha alimenti senza glutine?"
                        >
                            <Switch 
                            className="bg-gray-300"
                            checkedChildren={<Check className="mt-[2px]" size={16} />}
                            unCheckedChildren={<X className="mt-[1px]" size={16} />}
                            onChange={gluten} checked={clientInfo['glutenfree']} />
                        </Form.Item>
                        <Form.Item
                            label="Costo servizio senza glutine?"
                            tooltip="Inserisci il costo del servizio se disponibile"
                        >
                            <InputNumber min={0} max={99}  className="w-full"
                                name="glutenfree_cost"
                                formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                disabled={!clientInfo['glutenfree']}
                                value={clientInfo['glutenfree_cost']}
                                defaultValue={clientInfo['glutenfree_cost']}
                                onChange={onGlutenCost}
                            />
                        </Form.Item>

                        <Form.Item
                            label="La tua attività accetta animali domestici?"
                            tooltip="La tua attività accetta animali domestici?"
                        >
                            <Switch 
                            className="bg-gray-300"
                            checkedChildren={<Check className="mt-[2px]" size={16} />}
                            unCheckedChildren={<X className="mt-[1px]" size={16} />}
                            onChange={pet} checked={clientInfo['pet']} />
                        </Form.Item>

                        <Form.Item
                            label="Coperto"
                            tooltip="La tua attività ha un costo del coperto?"
                        >
                            <Switch 
                                className="bg-gray-300"
                                checkedChildren={<Check className="mt-[2px]" size={16} />}
                                unCheckedChildren={<X className="mt-[1px]" size={16} />}
                                onChange={coperto} checked={clientInfo['coperto_active']} />
                        </Form.Item>
                        <Form.Item
                            label="Costo del coperto"
                            required
                            tooltip="Inserisci costo del coperto se esistente della tua attività"
                        >
                            <InputNumber min={0} max={99} placeholder={clientInfo['coperto']}
                                className="w-full"
                                formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                value={clientInfo['coperto']}
                                name="coperto"
                                disabled={!clientInfo['coperto_active']}
                                defaultValue={clientInfo['coperto']}
                                onChange={onCopertoCost} />
                        </Form.Item>


                        <Form.Item
                            label="Alimenti vegani"
                            tooltip="La tua attività ha alimenti vegani?"
                        >
                            <Switch 
                            className="bg-gray-300"
                            checkedChildren={<Check className="mt-[2px]" size={16} />}
                            unCheckedChildren={<X className="mt-[1px]" size={16} />}
                            onChange={vegan} checked={clientInfo['veganfree']} />
                        </Form.Item>
                        <Form.Item
                            label="Costo servizio vegano?"
                            tooltip="Inserisci il costo del servizio se disponibile"
                        >
                            <InputNumber min={0} max={99}  className="w-full"
                                name="vegan_cost"
                                formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                disabled={!clientInfo['veganfree']}
                                value={clientInfo['vegan_cost']}
                                defaultValue={clientInfo['vegan_cost'] && 0}
                                onChange={onVeganCost}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Alimenti vegetariani"
                            tooltip="La tua attività ha alimenti vegetariani?"
                        >
                            <Switch 
                            className="bg-gray-300"
                            checkedChildren={<Check className="mt-[2px]" size={16} />}
                            unCheckedChildren={<X className="mt-[1px]" size={16} />}
                            onChange={vegetarian} checked={clientInfo['vegetarianfree']} />
                        </Form.Item>
                        <Form.Item
                            label="Costo servizio vegetariano?"
                            tooltip="Inserisci il costo del servizio se disponibile"
                        >
                            <InputNumber min={0} max={99}  className="w-full"
                                name="vegetarian_cost"
                                formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                disabled={!clientInfo['vegetarianfree']}
                                value={clientInfo['vegetarian_cost']}
                                defaultValue={clientInfo['vegetarian_cost'] && 0}
                                onChange={onVegetarianCost}
                            />
                        </Form.Item>




                        <Form.Item
                            label="La tua attività offre parcheggio per vetture?"
                        >
                            <Switch onChange={aprking} 
                            className="bg-gray-300"
                            checkedChildren={<Check className="mt-[2px]" size={16} />}
                            unCheckedChildren={<X className="mt-[1px]" size={16} />}
                            checked={clientInfo.parcheggio} />
                        </Form.Item>
                        <Form.Item
                            label="Wifi Gratuito"
                            tooltip="La tua attività offre internet gratuitamente?"
                        >
                            <Switch 
                            className="bg-gray-300"
                            checkedChildren={<Check className="mt-[2px]" size={16} />}
                            unCheckedChildren={<X className="mt-[1px]" size={16} />}
                            onChange={wifiSwitch} 
                            defaultChecked={(clientInfo['wifi_space'] != "")} />
                        </Form.Item>
                        <Form.Item
                            label="Wifi free"
                            tooltip="Inserisci la password del wifi"
                        >
                            <Input
                                name="wifi_space"
                                disabled={!wifi}
                                placeholder={clientInfo['wifi_space']}
                                value={clientInfo['wifi_space']}
                                defaultValue={clientInfo['wifi_space']}
                                onChange={onInputChange}
                            />
                        </Form.Item>

                            

                        <Form.Item
                            label="Hai una piscina"
                            tooltip="La tua attività ha una piscina?"
                        >
                            <Switch 
                                className="bg-gray-300"
                                checkedChildren={<Check className="mt-[2px]" size={16} />}
                                unCheckedChildren={<X className="mt-[1px]" size={16} />}
                                onChange={piscina} checked={clientInfo['piscina_active']} />
                        </Form.Item>

                        <Form.Item
                            label="Area fumatori"
                            tooltip="La tua attività ha un'area dedicata ai fumatori?"
                        >
                            <Switch 
                                className="bg-gray-300"
                                checkedChildren={<Check className="mt-[2px]" size={16} />}
                                unCheckedChildren={<X className="mt-[1px]" size={16} />}
                                onChange={smokingArea} checked={clientInfo['area_fumatori_active']} />
                        </Form.Item>

                        <Form.Item
                            label="Area giochi"
                            tooltip="La tua attività ha un area giochi per bimbi?"
                        >
                            <Switch 
                                className="bg-gray-300"
                                checkedChildren={<Check className="mt-[2px]" size={16} />}
                                unCheckedChildren={<X className="mt-[1px]" size={16} />}
                                onChange={parckGame} checked={clientInfo['area_giochi_active']} />
                        </Form.Item>


                        <Form.Item>
                            <Button htmlType="submit"
                                className="bg-brand-500 hover:!bg-brand-600"
                                type="primary"
                            >
                                Aggiorna
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        
        </>
    );
};

export default Banner;
