import React, { useRef, useState, useEffect } from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Divider, Space, Tour } from 'antd';
import Img1 from 'assets/img/dashboards/widget-tutorial.png';

const App = (props) => {
  const { refs, start } = props;
  const [open, setOpen] = useState(start);
  const [getRefs, setRefs] = useState({});
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    // if (refs && refs.current) {
      setRefs(refs.current);
    // }
  }, [refs]);

  useEffect(() => {
    if (getRefs && getRefs.ref1 && getRefs.ref2 && getRefs.ref3 && getRefs.ref4  && getRefs.ref5) {
      
      setSteps([
        {
          title: 'Widget statistiche',
          description: 'Qui verranno mostrate tutte le statistiche delle visualizzazioni e prenotazioni della tua attività.',
          cover: (
            <img
              alt="tour.png"
              src={Img1}
            />
          ),
          target: () => getRefs.ref1.current,
        },
        {
          title: 'Panoramica delle visualizzazioni',
          description: 'Controlla il traffico in ingresso attraverso l\'algoritmo di tracciabilità delle visualizzazioni del tuo menù.',
          target: () => getRefs.ref2.current,
        },
        {
          title: 'Traffico giornaliero',
          description: 'Tieni sott\'occhio il traffico giornaliero della tua attività e potenzia il tuo marketing.',
          target: () => getRefs.ref3.current,
        },
        {
          title: 'Visite in base al paese',
          placement: 'left',
          description: 'Controlla chi visualizza il tuo menù.',
          target: () => getRefs.ref4.current,
        },
        {
          title: 'Gestisci la tua attività',
          description: <>
            <p><b>Account:</b> Gestisci, modifica le impostazioni generali della tua attività </p>
            <p><b>Menù:</b> Gestisci, modifica e crea i tuoi menù digitali </p>
            <p><b>Categoria Menù:</b> Gestisci, modifica e crea le categorie dei tuoi menù </p>
            <p><b>Articoli:</b> Gestisci, modifica e crea gli articoli per i tuoi menù</p>
            <p><b>QRCODE:</b> Stampa il QRCode ed applicalo all'interno del tuo locale per scanerizzare il tuo menù in maniera comoda e veloce. </p>
          </>,
          placement: 'right',
          target: () => getRefs.ref5.current,
        },
        {
          title: 'Hai visogno di un ulteriore aiuto?',
          description: "Visita la pagina e visualizza i videotutoria dettagliati per la gestione della tua attività.",
          placement: 'bottom',
          target: () => getRefs.ref6.current,
        },
      ]);
    }
  }, [getRefs]);

  const handleStepChange = (step) =>{
    setOpen(false)

    if(step == 5){
      localStorage.setItem('tour', true)
    }
  }

  return (
    <>
      <Tour
        open={open}
        onClose={handleStepChange}
        steps={steps}
        indicatorsRender={(current, total) => (
          <span>
            {current + 1} / {total}
          </span>
        )}
      />
    </>
  );
};

export default App;
