import React, {useEffect, useState, forwardRef, useImperativeHandle, useRef} from 'react';
import MiniCalendar from "components/calendar/MiniCalendar";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import TotalSpent from "views/admin/default/components/TotalSpent";
import PieChartCard from "views/admin/default/components/PieChartCard";
import { IoMdHome } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";

import { columnsDataCheck, columnsDataComplex } from "./variables/columnsData";

import Widget from "components/widget/Widget";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import TaskCard from "views/admin/default/components/TaskCard";
import tableDataCheck from "./variables/tableDataCheck.json";
import tableDataComplex from "./variables/tableDataComplex.json";
import { ClientViews } from '../GraphQl/ClientInfo';
import Map from 'views/admin/default/components/Map'
import Tutorial from 'views/admin/default/Tutorial';
import Card from "components/card";


function getWeekNumber(d) {
	const a = new Date(d);
    a.setHours(0, 0, 0, 0);
    a.setDate(a.getDate() + 4 - (a.getDay() || 7));
    const yearStart = new Date(a.getFullYear(), 0, 1);
    const weekNo = Math.ceil((((a - yearStart) / 86400000) + 1) / 7);
    return [a.getFullYear(), weekNo];
}
function getStartOfWeek(d) {
    d = new Date(d);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1); // Regola per ottenere il lunedì
    return new Date(d.setDate(diff));
}

const Dashboard = forwardRef(({data, ...rest},ref) => {
	const [getClientViews] = ClientViews();
	const [views, setViews] = useState([]);
	const [countViews, setCountViews] = useState(0);
	const [countViewsSameUser, setCountViewsSameUser] = useState([]);
	const [devicedUsed, setDevicesUsed] = useState([]);
	const [countryVisit, setCountryVisited] = useState([]);
	const [cityVisit, setCityVisit] = useState([]);
	const [dateViews, setDateViews] = useState([]);
	

	useEffect(() => {
		if(Object.keys(data).length > 0){
			getClientViews({
				variables:{
					clientId: data._id,
				}
			}).then(({data: res, error, errors}) => { 
				// if(error || errors){ 
				// 	if(errors[0].extensions.code === "EXPIRED_TOKEN"){
				// 	  localStorage.removeItem('tk');
				// 	  window.location.href= "/";
				// 	}
				// 	if(error.graphQLErrors[0].extensions.code === "EXPIRED_TOKEN"){
				// 	  localStorage.removeItem('tk');
				// 	  window.location.href= "/";
				// 	}
				// 	message.open({
				// 	  key,
				// 	  type: "error",
				// 	  content: error.message,
				// 	});
				// 	return;
				//   }

				const clientView = res.clientView;
				setViews(clientView);

				let totViews = 0;
				let devices = [];
				let countrys = [];
				let sameUser = [];
				let weeklyStats = [];
				let dailyStats = [];
				let cityVist = [];

				const startOfWeek = getStartOfWeek(new Date());
				const endOfWeek = new Date(startOfWeek);
				endOfWeek.setDate(startOfWeek.getDate() + 6);


				clientView.forEach((element, i) => {
					
					totViews += element.visits;
					devices.push(element.device);


					
					const filterCountry = countrys.filter(e => e.country == element.country.country).length;
					if(filterCountry == 0){

						const cU = { 
							views: views.filter(e => e.country.country == element.country.country).length,
							progress: views.filter(e => e.country.country == element.country.country).length,
							country: element.country.country === undefined ? "Unknown" : element.country.country
						}
						
						countrys.push(cU)
					}
					const filterCity = cityVist.filter(e => e.name == element.country.city).length;
					if(filterCity == 0){
						if(element.country.country != undefined){
							const cities = { 
								name: element.country.city, 
								position: element.country.loc.split(','),
								views: views.filter(e => e.country.city == element.country.city).length,
							}
							
							cityVist.push(cities);
							
						}
					}
					
					
					const filterSameUser = sameUser.filter(e => e.country.ip == element.country.ip).length;

					if(filterSameUser == 0){
						const sU = { 
							visit: views.filter(e => e.country.ip == element.country.ip).length,
							device: element.device,
							country: element.country
						}
						sameUser.push(sU)
					}

					// const [year, week] = getWeekNumber(parseInt(element.createAt)); 
					// const weekKey = `${year}-W${week}`;
				
					// if (!weeklyStats[weekKey]) {
					// 	weeklyStats[weekKey] = 0;
					// }
					// weeklyStats[weekKey] += element.visits;
					
					
					

					const date = new Date(parseInt(element.createAt));
					
					if (date >= startOfWeek && date <= endOfWeek) {
						const dateKey = date.toISOString().split('T')[0]; // Formato YYYY-MM-DD
						if (!dailyStats[dateKey]) {
							dailyStats[dateKey] = 0;
						}
						dailyStats[dateKey] += element.visits;
					}
					
				});

				setCountViews(totViews)
				setDevicesUsed(devices);
				setCountryVisited(countrys);
				setCountViewsSameUser(sameUser);
				setDateViews(dailyStats);
				setCityVisit(cityVist)
			}).catch((error) => {
				console.error('Si è verificato un errore:', error);
				// message.open({
				//   key,
				//   type: "error",
				//   content: error.message,
				// });
			});
		}

		
	
	}, [data, views])

	// const ref1 = useRef(null);
	// const ref2 = useRef(null);


	const ref1 = useRef(null);
	const ref2 = useRef(null);
	const ref3 = useRef(null);
	const ref4 = useRef(null);
	// const localRef2 = useRef(null);

	useImperativeHandle(ref, () => ({
		...ref.current,
		ref1: ref1,
		ref2: ref2,
		ref3: ref3,
		ref4: ref4,
	}));


	return (
		<div>
			{/* <Tutorial step1={ref1} step2={ref2} /> */}
			<div {...rest} ref={ref1}  className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
				<Widget
					icon={<MdBarChart className="h-7 w-7" />}
					title={"Visite totali"}
					subtitle={countViews}
				/>
				<Widget
					icon={<MdBarChart className="h-6 w-6" />}
					title={"In tempo reale"}
					subtitle={Math.floor(Math.random() * 11)}
				/>
				<Widget
					icon={<MdBarChart className="h-6 w-6" />}
					title={"Prenotazioni totali"}
					subtitle={"In arrivo"}
				/>
			</div>

			<div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-12">
				{/* <TotalSpent />
				<WeeklyRevenue /> */}
				<div className='col-span-6'>
					<Card extra="flex flex-col bg-white w-full rounded-3xl py-6 pb-2 px-2 text-center" ref={ref2}>
						<Map cities={cityVisit} />
					</Card>
				</div>
				<div className='col-span-3'>
					<DailyTraffic date={dateViews} ref={ref3} />
				</div>
				<div className='col-span-3'>
					<ComplexTable
						columnsData={columnsDataComplex}
						ref={ref4}
						tableData={countryVisit}
					/>
				</div>
				
			</div>
		</div>
	);
});

export default Dashboard;
