import React, {useEffect, useState} from 'react';
import {  message } from "antd";
import Video1 from '../../../assets/videos/Tutorial - Come creare un menù.mp4';
import Video2 from '../../../assets/videos/Tutorial - Come creare una categoria.mp4';
import Video3 from '../../../assets/videos/Tutorial - Come inserire un prodotto.mp4';
import Thumbnail1 from '../../../assets/videos/thumbnail/Tutorial - Come creare un menù.png';
import Thumbnail2 from '../../../assets/videos/thumbnail/Tutorial - Come creare una categoria.png';
import Thumbnail3 from '../../../assets/videos/thumbnail/Tutorial - Come inserire un prodotto.png';
import Card from "components/card";

const ProfileOverview = ({data}) => {
  // const [getClientInfoFull] = ClientInfoFull();
 
  
  return (
    <div className="flex w-full flex-col gap-5">
      <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
        
        <div className='col-span-6'>
            <Card  extra={"items-left w-full p-[16px] bg-cover"}>
                <h2 className='text-2xl font-semibold mb-4'>Come Creare un menù</h2>
                <div>
                    <video width="100%" controls className='rounded-md' poster={Thumbnail1}>
                        <source src={Video1} type="video/mp4" />
                        Your browser does not support HTML video.
                    </video>
                </div>
            </Card>
        </div>

        <div className='col-span-6'>
            <Card  extra={"items-left w-full p-[16px] bg-cover"}>
                <h2 className='text-2xl font-semibold mb-4'>Come creare una categoria</h2>
                <div>
                    <video width="100%" controls className='rounded-md' poster={Thumbnail2}>
                        <source src={Video2} type="video/mp4" />
                        Your browser does not support HTML video.
                    </video>
                </div>
            </Card>
        </div>

        <div className='col-span-12'>
            <Card  extra={"items-left w-full p-[16px] bg-cover"}>
                <h2 className='text-2xl font-semibold mb-4'>Come inserire un prodotto</h2>
                <div>
                    <video width="100%" controls className='rounded-md' poster={Thumbnail3}>
                        <source src={Video3} type="video/mp4" />
                        Your browser does not support HTML video.
                    </video>
                </div>
            </Card>
        </div>
        
      </div>
    </div>
  );
};

export default ProfileOverview;
