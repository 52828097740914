import React, { useRef, useState,useEffect } from 'react';

import { PDFDocument, rgb } from 'pdf-lib';
import html2canvas from 'html2canvas';
import {  Card } from 'antd';
import QRCode from 'qrcode.react';
import { QrCode   } from "@phosphor-icons/react";

import { message, Button } from 'antd'
import axios from 'axios';


function App() {
    const divRef = useRef(null);
    const id = JSON.parse(localStorage.getItem("tk")).user._id;
    const [data, setData] = useState([]);
    const [load_data, setLoadData ] = useState(0);
    const [loadErr, setLoadErr] = useState(false);
    const [dataCollapse, setDataCollapse] = useState([]);
    const [user, setUser] = useState([]);
    const [allergensList, setAllergensList] = useState([]);
    const [galleryList, setGalleryList] = useState([]);

    
    const load = async () =>{
        if(load_data) return;
        

        const key = 'init';

        await axios.post(process.env.REACT_APP_END_POINT_API , {
            "query": {
                "clienti_infosById": {
                    "id": id,
                    "keys":[
                        "ID",
                        "activity_name",
                        "activity_type",
                        "Logo",
                        "header_cover",
                        "coperto",
                        "wifi_space",
                        "descriptions",
                        "Street",
                        "City",
                        "postal_code",
                        "facebook_link",
                        "tiktok_link",
                        "instagram_link",
                        "owner",
                        "owner_name",
                        "public_email",
                        "youtube_link",
                        "delivery",
                        "glutenfree",
                        "lactosefree",
                        "delivery_cost",
                        "glutenfree_cost",
                        "lactosefree_cost",
                        "ratings",
                        "parcheggio",
                        "client_type_filter",
                        "pet",
                        "phone"
                    ]
                },
                "allergenssMany": [
                    "ID",
                    "createAt",
                    "updateAt",
                    "title",
                    "description",
                    "image"
                ],
                "clienti_galleriasById": {
                    "id": {
                        "cliente": id
                    },
                    "keys": [
                        "ID",
                        "url",
                        "name",
                        "major"
                    ]
                }
            }
        },{
            headers: {
                'Content-Type': 'application/json',
                'auth-api-token': "ef29b61efc28755cbf9a513eb857bd181d60a423ba6c2f6f0c9b90308a41d771",
                'cache-ctr': "64c694b0ab6e70e4eeb0cb47"
            }
        }).then((response) => { console.log(response);
            //setMenuList();
            const user = response.data.models.clienti_infos
            const allergens = response.data.models.allergens

            setUser(user);
            setAllergensList(allergens);
            setGalleryList(response.data.models.clienti_gallerias);
        }).catch((err) => { console.log(err);
            if(err.response.status === 400){
                message.open({
                    key,
                    type: 'error',
                    content: err.response,
                });
            }
        });

        let arr_menus = [];
        let menus_ = [];
        
        await axios.post(process.env.REACT_APP_END_POINT_API , {
            "query": {
                "menusById": {
                    "id": {
                        "cliente": id,
                        "attivo": true
                    },
                    "keys":[
                        "ID",
                        "nome",
                        "attivo"
                    ]
                }
            }
        },{
            headers: {
                'Content-Type': 'application/json',
                'auth-api-token': "ef29b61efc28755cbf9a513eb857bd181d60a423ba6c2f6f0c9b90308a41d771",
                'cache-ctr': "64c694b0ab6e70e4eeb0cb47"
            }
        }).then((response) => { 
            //setMenuList();
            const menuList = response.data.models.menus
            
            //menus_ = menuList;
            
            axios.post(process.env.REACT_APP_END_POINT_API , {
                "query": {
                    "articolisById": {
                        "id": {
                            "utente": id
                        },
                        "keys":[
                            "ID",
                            "name",
                            "price",
                            "ingredients",
                            "gluten_free",
                            "lactose_free",
                            "allergens",
                            "photo",
                            "Show",
                            "cat_menu",
                            "utente",
                            "frozen",
                            "note"
                        ]
                    }
                }
            },{
                headers: {
                    'Content-Type': 'application/json',
                    'auth-api-token': "ef29b61efc28755cbf9a513eb857bd181d60a423ba6c2f6f0c9b90308a41d771",
                    'cache-ctr': "64c694b0ab6e70e4eeb0cb47"
                }
            }).then((response) => {
                setLoadData(true);
                const menus = response.data.models.articolis;
                

                let arr_menus = [];
                const oggettiOrdinati = {};
                let menus_ = [];
                
                let c = 0;
                
                menus.forEach((element, i) => { 
                    if(typeof element.utente === 'object' && element.utente !== null){
                        //setUser(element.utente);
                    }
    
                    
                    const getListMenu = menuList.map(item => item._id);
                    const getListMenuName = menuList.map(item => item.nome);
                    if(getListMenu.includes(element.cat_menu.ref_menu)){
                        const pos = getListMenu.indexOf(element.cat_menu.ref_menu);

                        getListMenuName.forEach(name => {
                            if(!arr_menus[name]){
                                arr_menus[name] = [];
                            }
                            
                        });
                        
                        let c = 0;
                        if(element.cat_menu.order === undefined){
                            c = 0
                        }else{
                            c = element.cat_menu.order;
                        }

                        if(!arr_menus[menuList[pos].nome][c + "@#" +element.cat_menu.nome]){
                            arr_menus[menuList[pos].nome][c + "@#" +element.cat_menu.nome] = [];
                            
                            arr_menus[menuList[pos].nome][c + "@#" +element.cat_menu.nome].push(element);
                        }else{
                            arr_menus[menuList[pos].nome][c + "@#" +element.cat_menu.nome].push(element);
                        }

                        // Estrai le chiavi (i nomi) in un array
                        const chiavi = Object.keys(arr_menus[menuList[pos].nome]);

                        // Ordina l'array di chiavi in base ai numeri nel nome
                        chiavi.sort((a, b) => {
                            // Estrai i numeri dalle chiavi utilizzando espressioni regolari
                            const numeroA = parseInt(a.match(/\d+/)[0]);
                            const numeroB = parseInt(b.match(/\d+/)[0]);

                            // Ordina in ordine decrescente
                            return numeroA - numeroB;
                        });

                        oggettiOrdinati[menuList[pos].nome] = [];
                        for (const chiave of chiavi) {
                            oggettiOrdinati[menuList[pos].nome][chiave] = arr_menus[menuList[pos].nome][chiave];
                        }
                        
                        //arr_menus[getListMenuName[pos]]= [];
                        //arr_menus[getListMenuName[pos]][element.cat_menu.nome] = [];
                        
                    }
                    //arr_menus[element.cat_menu._id].push([c]);
                    
                    menus_[i] = element;
                    c++;
                });

                
                
                /*menus_.forEach(element => { 
                    const getListMenu = menuList.map(item => item._id);
                    const getListMenuName = menuList.map(item => item.nome);
                    if(getListMenu.includes(element.cat_menu.ref_menu)){
                        const pos =getListMenu.indexOf(element.cat_menu.ref_menu);

                        
                        //console.log(element.cat_menu.nome);
                        //arr_menus[getListMenuName[pos]][element.cat_menu.nome].push(element);
                    }
                   
                });*/
                console.log(oggettiOrdinati);
                setDataCollapse(oggettiOrdinati);
                
                /*resCatMenu.forEach((element, i) => {
                    console.log(element);
                    setDataCollapse({
                        key: element._id,
                        label: element.name
                    });
                });*/
                
            }).catch((err) => { console.log(err);
                if(err.response.status === 400){
                    console.log(err.response.data);
                    message.error("Connessione fallita");
                    setLoadErr(true);
                }
            });
            
        }).catch((err) => {
            if(err.response.status === 400){
                message.open({
                    key,
                    type: 'error',
                    content: err.response,
                });
            }
        });
        
        
    }

    useEffect(() => {
        load();
    }, []);

    const createPDF = async () => {
        // Cattura il contenuto del div come un'immagine utilizzando html2canvas
        const div = divRef.current;
        const canvas = await html2canvas(div);

        // Crea un nuovo documento PDF
        const pdfDoc = await PDFDocument.create();
        const page = pdfDoc.addPage([canvas.width, canvas.height]);
        const pdfImage = await pdfDoc.embedPng(canvas.toDataURL('image/png'));

        // Disegna l'immagine catturata sul documento PDF
        page.drawImage(pdfImage, {
            x: 0,
            y: 0,
            width: canvas.width,//canvas.width,
            height: canvas.height//canvas.height,
        });

        // Salva il PDF in un buffer
        const pdfBytes = await pdfDoc.save();

        // Crea un URL blob per visualizzare o scaricare il PDF
        const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Puoi utilizzare pdfUrl per visualizzare il PDF nel browser o scaricarlo come file
        window.open(pdfUrl); // Apre il PDF in una nuova finestra/tab
    };

    return (
        <div>
            <div className="flex w-full flex-col gap-5" ref={divRef}>
                <div className="w-full mt-3 ">
                    <Card className="items-left w-full h-full p-[16px] bg-cover w-50">
                        <div className="w-full mt-5 mb-5">
                            <div className="flex">
                                <div className='w-full'>
                                    <h2 className='text-[3rem] font-bold'>Il nostro menù</h2>

                                    <div className='mt-6'>
                                    { 
                                        Object.keys(dataCollapse).map((item, i) => {
                                                const value = Object.values(dataCollapse)[i];
                                                return <div>
                                                        <h2 className='font-bold text-lg'>{item}</h2>
                                                        <div className='grid grid-cols-1 gap-6 lg:gap-8 sm:grid-cols-2 '>
                                                            {
                                                                Object.entries(value).map(([category, items], i) => { 
                                                                    
                                                                    return (
                                                                        <div className='space-y-8'>
                                                                            <p className='text-[1.2rem] mt-3'>{category.split("@#")[1]}</p>
                                                                        
                                                                            {   
                                                                                items.map((item, j) => { 
                                                                                    

                                                                                    return <div className="flex ml-2 mt-1">
                                                                                                <div  className='w-full'>
                                                                                                    <u>{item.name}</u>
                                                                                                    <p>
                                                                                                        <i>{item.ingredients.map((ing, index) => ing + (index + 1 == item.ingredients.length ? "" : ", "))}</i>
                                                                                                    </p>
                                                                                                    <div className='flex mt-2'>
                                                                                                        {
                                                                                                            item.gluten_free ?
                                                                                                                    <span className='mr-2'><img className='w-4' src={'https://media.mynuapp.it/images/allergens/glutine.png'} alt="" /></span>
                                                                                                                : ""
                                                                                                        }
                                                                                                        {
                                                                                                            item.lactose_free ?
                                                                                                                    <span className='mr-2'><img className='w-4' src={'https://media.mynuapp.it/images/allergens/latte.png'} alt="" /></span>
                                                                                                                : ""
                                                                                                        }
                                                                                                        {
                                                                                                            item.frozen ?
                                                                                                                    <span className='mr-2'><img className='w-4' src={'https://media.mynuapp.it/images/allergens/frozen.png'} alt="" /></span>
                                                                                                                : ""
                                                                                                        }
                                                                                                        {
                                                                                                            item.allergens != null ? 
                                                                                                                item.allergens.map((all, i) => { 
                                                                                                                    //console.log(props.allergens, all);
                                                                                                                    const allr = allergensList.filter((row, i) => {
                                                                                                                        if(row._id == all){
                                                                                                                            return row;
                                                                                                                        }
                                                                                                                    });
                                                                                                                    
                                                                                                                    
                                                                                                                    return <span className='mr-2'>
                                                                                                                                {
                                                                                                                                    <img className='w-4' src={allr[0].image} alt={allr[0].title} />
                                                                                                                                }
                                                                                                                            </span>
                                                                                                                })
                                                                                                            : ""
                                                                                                        }
                                                                                                        
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='w-80'>
                                                                                                    <p className='text-[#f6a31b] text-[1.5rem]'>{item.price} €</p>
                                                                                                </div>
                                                                                            </div>
                                                                                })
                                                                            }
                                                                        </div>
                                                                    )        
                                                                }
                                                                )
                                                            } 
                                                        </div>
                                
                                                    </div>
                                        }) 
                                    }
                                        
                                    </div>
                                </div>
                                <div>
                                    <QRCode
                                        value={"https://www.mynuapp.it/Menu/" + JSON.parse(localStorage.getItem('tk')).user.seo_url}
                                        size={300}
                                        renderAs="svg" // Renderizza il QRCode come SVG
                                        imageSettings={{
                                            src: "https://www.mynuapp.it/logo.png",
                                            x: undefined,
                                            y: undefined,
                                            height: 24,
                                            width: 24,
                                            excavate: true,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
            <Button type="primary" onClick={createPDF} className='mt-10 bg-brand-500 hover:!bg-brand-600' icon={<QrCode className='relative top-[1px]' size={20} />}><span className='relative -top-1'>Scarica QR CODE</span></Button>
            <button onClick={createPDF}>Genera PDF</button>
        </div>
    );
}

export default App;
