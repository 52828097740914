import { Form, Input, Modal, Switch,InputNumber } from "antd";
import { useState } from "react";
import {  X,Check  } from "@phosphor-icons/react";
import axios from "axios";
import { message } from "antd";

const App = (props) => {
    const [form] = Form.useForm();
    const [name, setName] = useState();
    const [attivo, setAttivo] = useState(true);
    const [priceBool, setPriceBool] = useState(false);
    const [priceMenu_, setPriceMenu] = useState(0);

    const handleOk = () => {
        
        if(name === undefined || name == ""){
            message.open({
                type: "error",
                content: "Dai un nome alla tua categoria",
            });
            return;
        }

        const key = "updatable";


        axios
        .post(
            process.env.REACT_APP_END_POINT_API,
            {
            query: {},
            mutation: {
                menusCreate: {
                    keys: {
                        createAt: new Date(),
                        updateAt: null,
                        nome: name,
                        attivo: attivo,
                        cliente: JSON.parse(localStorage.getItem('tk')).user._id,
                        prezzo_cost: priceMenu_,
                        prezzo_menu: priceBool
                    },
                },
            },
            },
            {
            headers: {
                "Content-Type": "application/json",
                "auth-api-token":"ef29b61efc28755cbf9a513eb857bd181d60a423ba6c2f6f0c9b90308a41d771",
                "cache-ctr": "64c694b0ab6e70e4eeb0cb47",
            },
            }
        )
        .then((response) => {
            if (response.data) {
                if (response.data.error) {
                    message.open({
                        key,
                        type: "error",
                        content: response.data.error,
                    });
                } else {
                    message.open({
                        key,
                        type: "success",
                        content: "Categoria aggiunta!",
                    });
                    props.ok(response.data.mutations.menus);
                    form.setFieldsValue({
                        name: '',
                        ref_menu: '',
                        prezzo_cost: '',
                        prezzo_menu: false
                    });
                    props.modalShow();
                }
            }
        })
        .catch((err) => {
            if (err.response.status === 400) {
                message.open({
                    key,
                    type: "error",
                    content: "Token d'accesso errato!",
                });
            }
        });
    };
    const handleCancel = () => {
        props.modalShow();
        form.setFieldsValue({
            name: '',
            ref_menu: '',
            prezzo_cost: '',
            prezzo_menu: false
        });
    };

    const onRequiredTypeChange = ({ name,  attivo}) => {
        if(name != undefined) setName(name);
        if(attivo != undefined) setAttivo(attivo);
        
    };

    const priceMenu= (e) => { 
        setPriceBool(e);
    }

    const onPriceMenu= (e) => {
        setPriceMenu(e);
    }

    
    
    return (
        <Modal
        okButtonProps={{
            className: "bg-indigo-700 hover:!bg-indigo-800",
        }}
        title="Aggiungi menù"
        className="!rounded-lg md:!px-24 !px-5 !w-full !top-16"
        classNames={{
            mask: "!bg-gray-600",
            header: "!bg-lightPrimary ml-4",
            content: "!bg-lightPrimary"
        }}
        open={props.show}
        onOk={handleOk}
        onCancel={handleCancel}
        >
            <Form
                form={form}
                layout="vertical"
                onValuesChange={onRequiredTypeChange}
                className="mt-4 p-3"
                initialValues={{
                    remember: false,
                }}
                autoComplete="off"
            >

                <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-12 md:col-span-7">
                        <div className="bg-white rounded-lg shadow">
                            <div className="py-3 px-6 border-b border-gray-200">
                                <div className="text-md font-medium">Informazioni Menù</div>
                            </div>
                            <div className="p-6">
                                <Form.Item
                                    label="Nome categoria menù"
                                    name="name"
                                    required
                                    defaultValue={name}
                                    tooltip="Inserisci un nome categoria per il menù"
                                >
                                    <Input placeholder="Es. Primi, Secondi, ecc.." defaultValue={name}/>
                                </Form.Item>
                            </div>
                        </div>
                        <div className="bg-white rounded-lg shadow mt-4">
                            <div className="py-3 px-6 border-b border-gray-200">
                                <div className="text-md font-medium">Costo</div>
                            </div>
                            <div className="p-6">
                                <Form.Item
                                    label="Menù fisso"
                                    name="prezzo_menu"
                                    tooltip="Aggiungi un prezzo al tuo menù"
                                >
                                    <Switch 
                                        className="bg-gray-300"
                                        name="prezzo_menu"
                                        checkedChildren={<Check className="mt-[2px]" size={16} />}
                                        unCheckedChildren={<X className="mt-[1px]" size={16} />}
                                        onChange={priceMenu} defaultChecked={priceBool} />
                                </Form.Item>
                                <Form.Item
                                    label="Costo menù fisso"
                                    name="prezzo_cost"
                                    tooltip="Inserisci il costo del servizio se disponibile"
                                >
                                    <InputNumber min={0} max={99}  className="w-full"
                                        disabled={!priceBool}
                                        name="prezzo_cost"
                                        formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        onChange={onPriceMenu}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-5">
                        <div className="bg-white rounded-lg shadow mb-4">
                            <div className="py-3 px-6 border-b border-gray-200">
                                <div className="text-md font-medium">Toggle</div>
                            </div>
                            <div className="p-6">
                                <Form.Item
                                    label="Abilita"
                                    name="attivo"
                                    required
                                    tooltip="Segna menù come attivo"
                                >
                                    <Switch
                                        defaultChecked
                                        className="bg-gray-300"
                                        checkedChildren={<Check className="mt-[2px]" size={16} />}
                                        unCheckedChildren={<X className="mt-[1px]" size={16} />}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                </div>

            </Form>
        </Modal>
    );
};
export default App;
